import Catalog from '@/components/Catalog2.vue';
import Home from './Home.vue';
import AnswerQuestion from './AnswerQuestion2.vue'; // import { mapState } from 'vuex'

export default {
  inject: ['send'],

  data() {
    return {};
  },

  components: {
    Home,
    Catalog,
    AnswerQuestion
  },
  computed: {
    // ...mapState({
    //   isPC:'isPC'
    // }),
    activeComponent() {
      return this.$store.state.component;
    }

  },

  beforeMount() {
    this.$store.commit('changeBase', 'KnowledgeBase');
    this.$store.commit('changeUserType', 'user');
  },

  mounted() {},

  unmounted() {},

  methods: {
    clickHome() {
      this.$store.commit('changeComponent', 'Home');
    }

  }
};