import { mapGetters, mapState } from "vuex";
export default {
  props: {
    questionRecordSelected: Object,
    scoreSelected: Object,
    show: {
      type: Boolean,
      default: true
    }
  },
  emits: ["update:show"],

  data() {
    return {
      scorePanel: {
        show: false,
        level: 0,
        oldLevel: 0,
        suggest: "",
        suggestChecked: false,
        optionChecked: []
      }
    };
  },

  computed: {},
  watch: {
    show: {
      handler(nv, ov) {
        this.scorePanel.show = nv;
      },

      immediate: true
    },
    scoreSelected: {
      handler(nv, ov) {
        if (!nv) {
          return;
        }

        this.scorePanel.level = nv.level;
        this.scorePanel.suggest = nv.suggest;
        this.scorePanel.optionChecked = nv.scoreOptionIdArray;
      },

      immediate: true
    }
  },
  components: {},
  computed: { ...mapState({
      questionRecordId: 'questionRecordId'
    }),
    ...mapGetters({
      score: "getScore"
    }),

    scoreActive() {
      let scoreActive = {};

      for (const score of this.score || []) {
        if (score.level === this.scorePanel.level) {
          scoreActive = score;
        }
      }

      return scoreActive;
    },

    scoreOptionActive() {
      let optionContentArray = [];

      for (let option of this.scoreActive.scoreOption || []) {
        if (this.scorePanel.optionChecked.indexOf(option.id) >= 0) {
          optionContentArray.push(option.content);
        }
      }

      return optionContentArray.join();
    }

  },

  mounted() {},

  methods: {
    close() {
      this.scorePanel.show = false;
      this.$emit("update:show", this.scorePanel.show);
    },

    clickScore() {
      this.$nextTick(() => {
        if (this.scorePanel.level === this.scorePanel.oldLevel) {
          this.scorePanel.level = 0;
        }

        this.scorePanel.oldLevel = this.scorePanel.level;
      });
    },

    // 用户取消评价
    clickChatAgain() {
      const res = this.$axios.post('/java/front/usersendFAQAppraise', {
        questionRecordId: this.questionRecordSelected.id,
        status: 1
      }); // console.log('用户取消',res);

      this.close();
    },

    changeQuestionRecord(questionId, questionRecordId, change) {
      this.$axios.post("/api/user/KnowledgeBase/changeQuestionRecord", {
        id: questionRecordId,
        question_id: questionId,
        change: change
      });
    },

    clickSubmitScore() {
      // // 提交评分
      this.addScore(); // // 问题已解决

      this.questionRecordSelected.is_solved = 1;
      this.changeQuestionRecord(this.questionRecordSelected.question_id, this.questionRecordSelected.id, {
        is_solved: this.questionRecordSelected.is_solved
      }); // 用户提交评价

      const res = this.$axios.post('/java/front/usersendFAQAppraise', {
        questionRecordId: this.questionRecordSelected.id,
        scoreId: this.scorePanel.level,
        scoreOptionIdArray: [],
        suggest: this.scorePanel.suggest,
        status: 0
      }); // 关闭评分面板

      this.close();
    },

    async addScore() {
      const res = await this.$axios.post("/api/user/ChatBase/addScore", {
        question_record_id: this.questionRecordSelected.id,
        score_id: this.scoreActive.id,
        score_option_id_array: this.scorePanel.optionChecked,
        suggest: this.scorePanel.suggest
      });

      if (res.status !== 200 || res.data.errno) {
        this.$notify({
          title: "Error",
          type: "error",
          message: "Some reason cause the failure of submission, please try again!"
        });
      }

      this.scorePanel.level = 0;
      this.scorePanel.oldLevel = 0;
      this.scorePanel.suggest = "";
      this.scorePanel.suggestChecked = false;
      this.scorePanel.optionChecked = [];
      this.$notify({
        title: this._t("successful"),
        type: "success",
        message: this._t("submitSuccessful")
      });
    }

  }
};