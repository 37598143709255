import Upload from "@/control/Upload.vue";
import ScorePanel from "@/control/ScorePanel.vue";
import { mapGetters, mapState } from "vuex";
import bus from 'vue3-eventbus';
import { ref } from 'vue';
export default {
  setup() {
    const isOnLine = ref(false);
    const userId = ref('');
    bus.on('event-adminOnlineData', messageData => {
      isOnLine.value = JSON.parse(messageData.data);
    });
    bus.on('init', messageData => {
      console.log('init', messageData);
      userId.value = messageData.userId;
    });
    return {
      isOnLine,
      userId
    };
  },

  data() {
    return {
      url: `${window.location.protocol == 'https:' ? 'wss' : 'ws'}://${window.location.hostname}:28288/faqwebsocket.ws`,
      uploadUrl: "/api/user/ChatBase/uploadFile",
      sendContent: "",
      timer: {},
      turn: false,
      fold: false,
      questionRecordCurrent: {},
      imgArray: [],
      img: "",
      imgIndex: 0,
      imgViewerShow: false,
      scorePanel: {
        show: false,
        level: 0,
        oldLevel: 0,
        suggest: "",
        optionChecked: []
      },
      scrollAjustById: {},
      websocket: undefined,
      messageData: '',
      cookieString: '',
      isUserApply: undefined,
      isApply: null,
      questionId: null
    };
  },

  components: {
    Upload,
    ScorePanel
  },
  computed: { ...mapState({
      isPC: "isPC",
      isScorePanelShow: "isScorePanelShow",
      questionRecord: "questionRecord",
      questionRecordId: "questionRecordId"
    }),
    ...mapGetters({
      score: "getScore",
      questionById: "getQuestionById",
      questionTypeById: "getQuestionTypeById",
      questionRecordById: "getQuestionRecordById",
      getPersonName: "getPersonName",
      getPersonBackgroundColor: "getPersonBackgroundColor",
      questionRecordUnreadCount: "getQuestionRecordUnreadCount",
      questionRecordUnsolvedCount: "getQuestionRecordUnsolvedCount"
    }),

    questionRecordSelected() {
      return this.questionRecordById[this.questionRecordId] || {};
    },

    questionRecordSort() {
      var questionRecord = _.cloneDeep(this.questionRecord);

      questionRecord.sort((a, b) => {
        const dateA = new Date(a.change_time).getTime();
        const dateB = new Date(b.change_time).getTime();
        return dateA < dateB ? 1 : -1;
      });
      questionRecord.sort((a, b) => {
        return a.is_solved - b.is_solved;
      }); // 未读消息

      var unreadCount = 0;

      for (const _record of questionRecord) {
        if (this.questionRecordId == 0) {
          this.$store.commit("changeQuestionRecordId", _record.id);
        } // 问题关联项目


        _record.item = this.questionRecordById[_record.id].item; // 问题相关评分

        _record.score = this.questionRecordById[_record.id].score; // 顶部消息统计

        if (_record.user_unread) {
          unreadCount = unreadCount + _record.user_unread;
        }

        _record.user = JSON.parse(_record.user || "{}");
      } // 闪烁提示未读消息


      var title = "wwt-faq";

      if (unreadCount) {
        clearInterval(this.timer);
        var it = this;
        this.timer = setInterval(() => {
          it.turn = !it.turn;

          if (it.turn) {
            document.title = title + "[" + unreadCount + "message]";
          } else {
            document.title = title;
          }
        }, 1000);
      } else {
        clearInterval(this.timer);
        document.title = title;
      }

      return questionRecord;
    },

    title() {
      if (this.questionRecordId == 0) {
        return "";
      }

      var record = this.questionRecordById[this.questionRecordId];

      if (record.question_id != 0 && this.questionById[record.question_id]) {
        // 知识库问题
        return this.questionById[record.question_id].title;
      } else {
        // 用户自定义问题
        return record.title;
      }
    },

    chatReocrd() {
      if (this.questionRecordId == 0 || _.isEmpty(this.questionRecordById)) {
        return;
      }

      var record = this.questionRecordById[this.questionRecordId];

      var chatById = _.cloneDeep(record.chat);

      if (_.isEmpty(record)) {
        record = this.questionRecordCurrent;
      } else {
        this.questionRecordCurrent = record;
      } // 将评分插入聊天中


      for (const score of this.questionRecordCurrent.score) {
        chatById['score' + score.id] = {
          id: 0,
          time: score.time,
          user: this.questionRecordCurrent.user,
          person: this.questionRecordCurrent.person,
          type: 'score',
          score,
          new_content: ''
        };
      }

      this.imgArray = [];
      var index = 0;

      for (var chatId in chatById) {
        var chat = chatById[chatId]; // 搜索图片，替换为element框架<el-image>

        var reg1 = new RegExp(/<img src=['|"]([\s\S]+?)['|"]/, 'g');
        var regRet1 = reg1.exec(chat.new_content);

        if (_.isEmpty(regRet1) || regRet1.length <= 1) {} else {
          var imgMatch = regRet1.slice(1);

          for (var src of imgMatch || []) {
            this.imgArray.push(src);
            chat.new_content = chat.new_content.replace(/<img src=/, "<img class='chatImg' style='width:100%;cursor:pointer;' data-index=" + index + " src=");
            index++;
          }
        } // 搜索视频，修改尺寸


        var reg2 = new RegExp(/<video class="ql-video" controls="true" preload="none" src=['|"]([\s\S]+?)['|"]/, 'g');
        var regRet2 = reg2.exec(chat.content);

        if (_.isEmpty(regRet2) || regRet2.length <= 1) {} else {
          var videoMatch = regRet2.slice(1);

          for (var src of videoMatch || []) {
            chat.content = chat.content.replace(/<video class="ql-video" controls="true" preload="none" src=/, '<video width="100%" x5-video-player-type="h5" x5-playsinline="true"  webkit-playsinline="true" x-webkit-airplay="true" playsinline="true" class="ql-video" controls="true" preload="none" src=');
          }
        }
      }

      this.$nextTick(() => {
        this.addChatImgClick();
      }); // 排序

      const chatSort = [];

      for (var chatId in chatById) {
        var chat = chatById[chatId];
        chatSort.push(chat);
      }

      chatSort.sort((a, b) => {
        const aMoment = this.$moment(a.time).valueOf();
        const bMoment = this.$moment(b.time).valueOf();
        return aMoment - bMoment;
      }); //将评分放到最后
      // let score =
      //   this.questionRecordCurrent.score[
      //     this.questionRecordCurrent.score.length - 1
      //   ];
      // score &&
      //   chatSort.push({
      //     id: 0,
      //     time: score.time,
      //     user: this.questionRecordCurrent.user,
      //     person: this.questionRecordCurrent.person,
      //     type: "score",
      //     score,
      //     content: "",
      //   });

      return chatSort;
    },

    scoreActive() {
      let scoreActive = {};

      for (const score of this.score || []) {
        if (score.level === this.scorePanel.level) {
          scoreActive = score;
        }
      }

      return scoreActive;
    },

    userDisabled() {
      return !!(this.questionRecordSelected && this.questionRecordSelected.is_solved) || false;
    }

  },
  watch: {
    chatReocrd: {
      handler(nv, ov) {
        const chatLength = this.chatReocrd && Object.keys(this.chatReocrd).length || 0;

        if (chatLength) {
          this.scrollAjust(this.questionRecordId, chatLength);
        }
      },

      flush: "post"
    },
    isScorePanelShow: {
      handler(nv, ov) {
        this.scorePanel.show = nv;
      },

      immediate: true
    },
    questionRecordId: {
      handler(nv, ov) {
        this.questionId = nv;
        this.getFAQAppraiseStatus();
      },

      immediate: true
    },
    "scorePanel.show": {
      handler(nv, ov) {
        this.$store.commit('changeIsScorePanelShow', nv);
      }

    } // isApply: {
    //   handler(nv, ov) {
    //     if(nv === true) {
    //       this.scorePanel.show = true;
    //     }
    //   },
    //   immediate: true
    // }

  },

  mounted() {},

  beforeUnmount() {
    this.sendUserPageCloseingEvent();
  },

  methods: {
    // 客户关闭页面事件
    async sendUserPageCloseingEvent() {
      const res = await this.$axios.get(`/java/front/sendUserPageCloseingEvent?uid=${this.userId}`);
    },

    async getFAQAppraiseStatus() {
      if (this.questionId == 0) {
        return;
      }

      const res = await this.$axios.get('/java/front/getFAQAppraiseStatus?questionRescordId=' + this.questionId);
      this.isApply = res.data.data;

      if (res.data.data == 1) {
        this.scorePanel.show = true;
      } else if (res.data.data != 1) {
        this.scorePanel.show = false;
      }
    },

    scrollAjust(questionRecordId, chatLength) {
      if (this.scrollAjustById[0] !== questionRecordId || this.scrollAjustById[questionRecordId] !== chatLength) {
        if (this.$refs.chat) {
          this.$refs.chat.scrollTop = this.$refs.chat.scrollHeight;
        }
      }

      this.scrollAjustById[0] = questionRecordId;
      this.scrollAjustById[questionRecordId] = chatLength;
    },

    getNav(id) {
      var record = this.questionRecordById[id];
      var type = this.questionTypeById[record.question_type_id];
      var nav = [];

      for (const _nav of type.nav) {
        nav.push(_nav.title);
      }

      return nav;
    },

    getTitle(id) {
      var record = this.questionRecordById[id];

      if (record.question_id != 0 && this.questionById[record.question_id]) {
        // 知识库问题
        return this.questionById[record.question_id].title;
      } else {
        // 用户自定义问题
        return record.title;
      }
    },

    getItemName(item) {
      var nameArray = [];

      for (const _id in item) {
        const _item = item[_id];
        nameArray.push(_item.name);
      }

      return nameArray.join();
    },

    getItemNameAndDescription(item) {
      var nameArray = [];

      for (const _id in item) {
        const _item = item[_id];

        if (_item.description) {
          nameArray.push(_item.name + "(" + _item.description + ")");
        } else {
          nameArray.push(_item.name);
        }
      }

      return nameArray.join();
    },

    clickReturnKnowledge() {
      this.$router.push({
        path: "/user/knowledge"
      });
    },

    clickQuestionRecord(record) {
      this.$store.commit("changeQuestionRecordId", record.id);
    },

    removeUnvalidContent(content) {
      const unvaild = "<p></p>";

      if (content.endsWith(unvaild)) {
        content = content.slice(0, -unvaild.length);
        content = this.removeUnvalidContent(content);
      }

      return content;
    },

    appendHtmlContent(content) {
      return "<p>" + content + "</p>";
    },

    clickSendContent() {
      var sendContent = this.sendContent;
      sendContent = this.appendHtmlContent(sendContent);
      sendContent = this.removeUnvalidContent(sendContent);

      if (sendContent.trim() == "") {
        return;
      }

      this.$axios.post("/api/user/ChatBase/sendContent", {
        question_record_id: this.questionRecordId,
        content: sendContent
      }).then(res => {
        if (res.data.errno) {} else {
          this.sendContent = "";
        }
      }).catch(err => {
        console.log(err);
      });
    },

    changeQuestionRecord(questionId, questionRecordId, change) {
      this.$axios.post("/api/user/KnowledgeBase/changeQuestionRecord", {
        id: questionRecordId,
        question_id: questionId,
        change: change
      });
    },

    async changingProblemsState() {
      if (this.questionId == 0) {
        return;
      }

      const res = await this.$axios.get('/java/front/changingProblemsState?questionRescordId=' + this.questionId);
    },

    clickSolved(questionRecord) {
      if (!questionRecord.is_solved) {
        this.scorePanel.show = !this.scorePanel.show;
      } else {
        questionRecord.is_solved = questionRecord.is_solved ? 0 : 1;
        this.changeQuestionRecord(questionRecord.question_id, questionRecord.id, {
          is_solved: questionRecord.is_solved
        });
        this.changingProblemsState();
      }
    },

    getLocalTime(time) {
      var localTime = this.$moment.utc(time).local().format("YYYY-MM-DD HH:mm:ss");
      return localTime;
    },

    clickFoldPanel() {
      this.fold = !this.fold;
    },

    onSuccessUpload(embed) {
      this.sendEmbed(embed);
    },

    sendEmbed(embed) {
      const sendContent = embed;
      this.$axios.post("/api/user/ChatBase/sendContent", {
        question_record_id: this.questionRecordId,
        content: sendContent
      }).then(res => {
        if (res.data.errno) {} else {}
      }).catch(err => {
        console.log(err);
      });
    },

    addChatImgClick() {
      const it = this;
      var chatImgArray = document.getElementsByClassName("chatImg");

      for (var img of chatImgArray) {
        img.onclick = e => {
          var index = e.target.dataset.index;
          it.imgIndex = Number(index);
          it.imgViewerShow = true;
        };
      }
    }

  }
};