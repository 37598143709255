export default {
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["英语"])},
  "cn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中文"])},
  "afterSales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["售后"])},
  "afterSalesQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["售后问题"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["语言"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有"])},
  "viewAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看所有"])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增"])},
  "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["负责人"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["标题"])},
  "linkItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关联项目"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["描述"])},
  "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["步骤"])},
  "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["内容"])},
  "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["答案"])},
  "nextStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下个步骤"])},
  "addStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增步骤"])},
  "begEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开始编辑"])},
  "endEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存编辑"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除"])},
  "addType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增问题类型"])},
  "addChildType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增子问题类型"])},
  "addQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增问题"])},
  "addChildQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增子问题"])},
  "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已解决"])},
  "unsolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未解决"])},
  "unsolvedAndChatNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["问题未解决，转人工客服"])},
  "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["位置"])},
  "deleteQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除问题"])},
  "changeType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["修改问题类型"])},
  "deleteType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除问题类型"])},
  "returnKnowledgeBase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回知识库"])},
  "viewUserQuestionStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看用户问题步骤"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送"])},
  "itemManage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["项目管理"])},
  "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编号"])},
  "item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["项目"])},
  "operation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作"])},
  "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["修改"])},
  "needStaffService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在当前目录下未找到相关问题，寻求人工帮助（需先登录）"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录"])},
  "reconnecting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["系统重连中..."])},
  "identifying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["验证账户中..."])},
  "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账户"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码"])},
  "store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商店"])},
  "homeHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请在左侧目录中选择相关问题"])},
  "productFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["产品筛选"])},
  "needHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人工客服"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类型"])},
  "alterFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["修改失败"])},
  "alterFailedHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由于本地数据不是最新，导致修改失败，请刷新网页重试。"])},
  "loginSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录成功"])},
  "loginFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录失败"])},
  "loginNeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请先登录!"])},
  "chatNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人工客服"])},
  "unread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未读"])},
  "unresolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未解决"])},
  "backToLastStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退回上一步骤"])},
  "allItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有产品"])},
  "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["备注"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交"])},
  "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户"])},
  "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数量"])},
  "ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申请单"])},
  "questionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["问题类型"])},
  "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["问题"])},
  "faultItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["问题产品"])},
  "syncItemInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同步产品信息"])},
  "viewOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看订单"])},
  "num": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编号"])},
  "orderNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单号"])},
  "orderStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单状态"])},
  "payStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付状态"])},
  "shipStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发货状态"])},
  "getOrderFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["获取订单失败"])},
  "gettingOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["获取订单中"])},
  "getOrderEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无数据"])},
  "unsolvedSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你确定此单未解决?"])},
  "solvedSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你确定此单已解决?"])},
  "uploadProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上传进度"])},
  "checkAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全选"])},
  "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已解决"])},
  "noReply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未回复"])},
  "mixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态"])},
  "unrated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未评分"])},
  "recall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["撤回"])},
  "loadMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加载更多"])},
  "inviteScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["感谢您为本次服务评分"])},
  "chatAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["继续沟通"])},
  "inputSuggest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入您的建议..."])},
  "sendPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入发送内容..."])},
  "CustomerServiceOnlineStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客服在线状态"])},
  "goToChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前往人工客服"])},
  "goToLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前往登录界面"])},
  "createTicketFistly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请先提交一个问题"])},
  "suggestCheckBox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他，请留下您的建议:"])},
  "yourComments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的评价："])},
  "yourSuggest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的建议："])},
  "createTicketSuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交成功， 即将进入聊天页面，如果无法进入该页面，请检查您的浏览器设置。"])},
  "createTicketFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建TICKET失败,请勿在短时间内重复创建TICKET。您可以通过点击左侧目录上方的“售后问题”按钮与我们联系"])},
  "inputQuestionHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入您的问题..."])},
  "rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评分:"])},
  "serviceTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务时间"])},
  "workTimeHint1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尊敬的用户，我们提供服务的时间为：周一到周五9:00一18:00。"])},
  "workTimeHint2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目前时间为："])},
  "workTimeHint3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请留言，我们客服人员上线后将会将会第一时间回复您的问题。"])},
  "workTimeHint4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["感谢理解"])},
  "successful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功"])},
  "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["警告"])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["错误"])},
  "modifySuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["修改数据成功。"])},
  "modifyFaild": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["修改数据失败，请稍后重试。"])},
  "submitSuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交成功"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["首页"])},
  "shopping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商店"])},
  "collocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的座舱"])},
  "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下载中心"])},
  "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务中心"])},
  "regular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["常规下载"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系我们"])},
  "curLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中国大陆/简体中文"])},
  "logIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录"])},
  "signUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册"])},
  "userCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户中心"])},
  "myOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的订单"])},
  "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退出"])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关于我们"])},
  "clause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务条款"])},
  "problem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["常见问题"])},
  "winwing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["翼胜科技"])},
  "backTop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回到顶部"])},
  "enterEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入您的邮箱"])},
  "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订阅WW新闻"])},
  "moblieHomeHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请在上面目录选择您的问题"])},
  "knowledgeAndSupportTicketSystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务申请与信息支持"])},
  "knowledgeCenterSystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["解决方案自助查询"])},
  "findSolutionInKnowledgeCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在“知识库”中自助查询并获取解决方案"])},
  "supoortTicketSystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人工服务申请"])},
  "leadLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["系统引导登陆，申请人工客服支持"])},
  "IfCantGetSolutionInKnowledgeCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(如无法在“知识库”中获取解决方案)"])},
  "IfNoSolutionIsAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["假如您遇到的问题无法通过“知识库”自助查询并获取解决方案，我们会引导您进行登陆，并申请人工支持。“知识库”能协助您快速、精准的解决问题，同时“客服系统”将为您提供更贴心的服务。"])},
  "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["图片"])},
  "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["视频"])},
  "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件"])},
  "subscribedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订阅成功"])},
  "subscribedFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订阅失败，请联系我们。"])},
  "maxUpload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上传文件的大小建议小于100M。"])},
  "hotas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOTAS套装"])},
  "combo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["摇杆/油门/总距套装"])},
  "controlPanel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["模拟飞行面板"])},
  "gripAndHandle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["模拟飞行杆头"])},
  "base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["摇杆/油门/总距底座"])},
  "accessories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配件"])},
  "failUpload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上传失败！文件服务器维护中，请稍后再试。"])},
  "ContactCustomerSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系人工客服（7*16小时在线）"])},
  "LiveChatisopenMondaytoSunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["周一至周日"])},
  "Onlinetimeperiod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["周一到周日，8:00-12:00，13:00-00:30(北京时间)"])},
  "Onlinetime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(周一至周日，8:00-12:00，13:00-00:30，北京时间)"])}
}