import { mapGetters, mapState } from 'vuex';
export default {
  components: {},
  props: {
    show: {
      type: Boolean,

      default() {
        return false;
      }

    },
    questionType: {
      type: Object,

      default() {
        return undefined;
      }

    },
    question: {
      type: Object,

      default() {
        return undefined;
      }

    },
    questionRecord: {
      type: String,

      default() {
        return undefined;
      }

    }
  },

  data() {
    return {
      modalShow: false,
      nav: [],
      newQuestionType: {},
      questionTitle: '',
      itemIdArray: [],
      hint: '',
      btnLoading: false
    };
  },

  watch: {
    show: function (nv, ov) {
      this.modalShow = nv;

      if (nv) {
        // this.getOrderByUser();
        if (this.questionType) {
          this.nav = this.questionType && this.questionType.nav || [];
        }

        if (this.question) {
          this.nav = this.question && this.question.nav || [];
        }

        this.questionTitle = this.question && this.question.title;
      } // 隐藏main-content


      const mainContent = document.getElementById('main-content');
      mainContent.style.display = nv ? 'none' : 'block';
    },
    modalShow: function (nv, ov) {
      this.$emit('update:show', nv);
    }
  },
  computed: { ...mapState({
      order: 'order',
      item: 'item'
    }),
    ...mapGetters({
      getQuestionTypeNavShow: 'getQuestionTypeNavShow',
      getQuestionNavShow: 'getQuestionNavShow',
      itemByName: 'getItemByName',
      getQuestionTypeByParentId: 'getQuestionTypeByParentId',
      questionTypeArrayByParentId: 'questionTypeArrayByParentId'
    }),

    disabled() {
      return !_.isEmpty(this.question);
    }

  },

  activated() {
    this.hint = '';
  },

  methods: {
    clickAddQuestionRecord() {
      this.btnLoading = true;

      if (this.nav.length < 2) {
        this.hint = 'The level of QuestionType must > 1!';
        this.btnLoading = false;
        return;
      }

      if (_.isEmpty(this.questionTitle) || this.questionTitle.trim() == '') {
        this.hint = "The Question can't be empty!";
        this.btnLoading = false;
        return;
      }

      if (this.questionTitle.length > 100) {
        this.hint = 'Please input question simple expression, more details description could be chat after submit a ticket.Make sure no more than 100 characters';
        this.btnLoading = false;
        return;
      }

      var questionTypeId = this.nav[this.nav.length - 1] && this.nav[this.nav.length - 1].id || 0;
      var params = {
        question_type_id: questionTypeId
      };

      if (this.question) {
        params.question_id = this.question.id;
      } else {
        params.title = this.questionTitle;
      }

      if (this.questionRecord) {
        params.question_record = this.questionRecord;
      }

      if (!_.isEmpty(this.itemIdArray)) {
        params.item_id_array = this.itemIdArray;
      }

      this.$axios.post('/api/user/KnowledgeBase/addQuestionRecord', params).then(res => {
        if (!res.data.errno) {
          this.modalShow = false;
          this.$msgbox({
            title: 'Success',
            message: this._t('createTicketSuccessful'),
            type: 'success',
            center: true
          });
          this.$store.dispatch('openChat');
        } else {
          if (res.data.errmsg == 'Create Ticket in 5 minute again') {
            this.hint = this._t('createTicketFailed');
          }
        }

        this.btnLoading = false;
      }).catch(err => {
        this.btnLoading = false;
      });
    },

    changeAddNav() {
      this.newQuestionType && this.nav.push({
        id: this.newQuestionType.id,
        title: this.newQuestionType.title
      });
      this.newQuestionType = undefined;
    }

  }
};