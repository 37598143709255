import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue";
const _hoisted_1 = {
  style: {
    "width": "100%"
  }
};
const _hoisted_2 = {
  class: "container"
};
const _hoisted_3 = {
  class: "el-submenu__title",
  style: {
    "width": "360px",
    "padding": "1rem"
  }
};
const _hoisted_4 = ["title"];
const _hoisted_5 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_MoblieTicket = _resolveComponent("MoblieTicket");

  const _component_el_tooltip = _resolveComponent("el-tooltip");

  const _component_el_option = _resolveComponent("el-option");

  const _component_el_select = _resolveComponent("el-select");

  const _component_el_tree = _resolveComponent("el-tree");

  const _component_van_icon = _resolveComponent("van-icon");

  const _component_van_cell = _resolveComponent("van-cell");

  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_MoblieTicket, {
    show: _ctx.ticket.show,
    "onUpdate:show": _cache[0] || (_cache[0] = $event => _ctx.ticket.show = $event),
    questionType: _ctx.ticket.questionType
  }, null, 8, ["show", "questionType"]), _createVNode(_component_el_tooltip, {
    effect: "light",
    placement: "top",
    content: _ctx.ticketTooltipContent
  }, {
    default: _withCtx(() => [_createElementVNode("div", {
      class: "ticket",
      style: _normalizeStyle(_ctx.ticketStyle),
      onClick: _cache[1] || (_cache[1] = $event => _ctx.clickToAfterSales()),
      ref: "left_ticket"
    }, _toDisplayString(_ctx._t("afterSalesQuestion")) + "(" + _toDisplayString(_ctx.questionRecordUnreadCount) + "/" + _toDisplayString(_ctx.questionRecordUnsolvedCount) + ")", 5)]),
    _: 1
  }, 8, ["content"]), _createElementVNode("div", _hoisted_1, [_createVNode(_component_el_select, {
    class: "van-dropdown-menu2",
    clearable: "",
    modelValue: _ctx.itemFilterSelected,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.itemFilterSelected = $event),
    placeholder: _ctx._t('productFilter'),
    "value-key": "id",
    onVisibleChange: _ctx.changeItemFilter,
    multiple: "",
    onChange: _ctx.autoSetTreeHeight,
    onRemoveTag: _ctx.autoSetTreeHeight
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getValidItem, _item => {
      return _openBlock(), _createBlock(_component_el_option, {
        key: _item.id,
        value: _item.id,
        label: _item.name
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_item.name) + _toDisplayString(_item.description ? '(' + _item.description + ')' : ''), 1)]),
        _: 2
      }, 1032, ["value", "label"]);
    }), 128))]),
    _: 1
  }, 8, ["modelValue", "placeholder", "onVisibleChange", "onChange", "onRemoveTag"])]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_el_tree, {
    ref: "left_tree",
    class: "tree1",
    data: _ctx.catalogContent,
    props: _ctx.catalogProps,
    "default-expanded-keys": [_ctx.questionId],
    "default-active": "2",
    "node-key": "id",
    "highlight-current": "",
    onNodeClick: _ctx.clickElement,
    "background-color": "#ffffff",
    "text-color": "#1c1d1f",
    "active-text-color": "#1c1d1f"
  }, {
    default: _withCtx(({
      node,
      data
    }) => [_createElementVNode("span", _hoisted_3, [!data.isQuestion ? (_openBlock(), _createElementBlock("i", {
      key: 0,
      class: _normalizeClass(node.expanded ? 'el-icon-remove-outline' : 'el-icon-circle-plus-outline'),
      style: {
        "font-size": "18px",
        "width": "24px",
        "text-align": "center"
      }
    }, null, 2)) : _createCommentVNode("", true), _createElementVNode("span", {
      title: node.label
    }, _toDisplayString(node.label), 9, _hoisted_4), !data.isQuestion ? (_openBlock(), _createElementBlock("i", {
      key: 1,
      class: "tree-node-ticket el-icon-chat-dot-round",
      onClick: _withModifiers($event => _ctx.clickTicket($event, data), ["stop"])
    }, null, 8, _hoisted_5)) : _createCommentVNode("", true)])]),
    _: 1
  }, 8, ["data", "props", "default-expanded-keys", "onNodeClick"])]), _createElementVNode("div", {
    class: "van-cell-icon",
    onClick: _cache[3] || (_cache[3] = (...args) => _ctx.foldAllTree && _ctx.foldAllTree(...args))
  }, [_createVNode(_component_van_cell, null, {
    default: _withCtx(() => [_createVNode(_component_van_icon, {
      name: "/moblie/images/aftersale-top.png"
    })]),
    _: 1
  })])]);
}