import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "page-custservi"
};
const _hoisted_2 = {
  class: "main-content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_MoblieHeader = _resolveComponent("MoblieHeader");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_MoblieHeader), _createElementVNode("section", _hoisted_2, [(_openBlock(), _createBlock(_KeepAlive, null, [(_openBlock(), _createBlock(_resolveDynamicComponent($options.activeComponent)))], 1024))])]);
}