export default {
  props: {
    fileType: {
      type: String,
      default: 'file' // [image,video,file]

    },
    url: {
      type: String,
      default: '/api/admin/KnowledgeBase/uploadFile'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['onProgress', 'onSuccess'],

  data() {
    return {
      accept: '',
      iconUrl: '',
      name: '',
      uploadLoaded: 0,
      uploadTotal: 0
    };
  },

  watch: {
    fileType: {
      handler(nv, ov) {
        if (nv === 'image') {
          this.name = this._t('image');
          this.accept = 'image/*';
          this.iconUrl = '/moblie/images/cart-icon-img.png';
        } else if (nv === 'video') {
          this.name = this._t('video');
          this.accept = 'video/*';
          this.iconUrl = '/moblie/images/cart-icon-video.png';
        } else if (nv === 'file') {
          this.name = this._t('file');
          this.accept = '';
          this.iconUrl = '/moblie/images/cart-icon-file.png';
        }
      },

      immediate: true
    }
  },
  components: {},
  computed: {
    fileId() {
      return 'quill_file' + this.$.uid;
    },

    fileRef() {
      return this.$refs && this.$refs[this.fileId];
    },

    uplodingProgress() {
      if (this.uploadTotal === 0) {
        return 0;
      }

      const uploadProgress = (this.uploadLoaded / this.uploadTotal * 100).toFixed(1);
      return Number(uploadProgress);
    },

    uplodingText() {
      return 'Uploading...';
    }

  },

  mounted() {},

  methods: {
    clickUpload(e) {
      if (this.disabled) {
        return;
      }

      if (this.fileRef) {
        this.fileRef.accept = this.accept;
        this.fileRef.click();
      }
    },

    changeUpload(e) {
      var files = e.target.files;

      if (files.length == 0) {
        return;
      } // FormData


      var formData = new FormData();

      for (let index = 0; index < files.length; index++) {
        let file = files[index];
        formData.append('file' + index, file);
      }

      this.fileRef.value = '';
      this.$axios.post(this.url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: e => {
          this.uploadLoaded = e.loaded;
          this.uploadTotal = e.total;
          this.$emit('onProgress', e);
        }
      }).then(res => {
        if (res.data.errno) {} else {
          let embed = '';

          for (const _file of res.data.data) {
            embed = embed + this.packEmbed(_file.url, _file.name);
          }

          if (embed !== '') {
            this.$emit('onSuccess', embed);
          }

          this.uploadLoaded = 0;
          this.uploadTotal = 0;
        }
      }).catch(err => {
        this.uploadLoaded = 0;
        this.uploadTotal = 0;
        console.error(err);
        this.$notify({
          title: this._t('error'),
          type: 'error',
          message: this._t('maxUpload')
        });
      });
    },

    packEmbed(url, name) {
      let embed = '';

      if (this.fileType === 'image') {
        embed = `<img src="${url}">`;
      } else if (this.fileType === 'video') {
        embed = `<video class="ql-video" controls="true" preload="none" src="${url}"></video>`;
      } else if (this.fileType === 'file') {
        embed = `<a class="bi bi-file-earmark" href="${url}" download="${name}" contenteditable="false">${name}</a>`;
      }

      return embed;
    }

  }
};