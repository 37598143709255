import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-069c47ea"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "flex-center",
  style: {
    "width": "100%",
    "height": "100%"
  }
};
const _hoisted_2 = {
  style: {
    "color": "#999",
    "font-size": "1.5rem"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("span", _hoisted_2, _toDisplayString(_ctx._t("homeHint")), 1)]);
}