import { markRaw } from 'vue';
export default {
  props: {
    disabled: {
      type: [Boolean, Number],
      default: false
    },
    showAI: {
      type: Boolean,
      default: false
    },
    modelValue: String,
    url: {
      type: String,
      default: '/api/admin/KnowledgeBase/uploadFile'
    },
    theme: {
      type: String,
      default: 'edit'
    } // ['edit','chat']

  },
  emits: ['update:modelValue', 'ready', 'blur', 'focus', 'change', 'fileUpload'],

  data() {
    return {
      content: '',
      changeCount: 0,
      quill: {},
      accept: '',
      type: '',
      hasFocus: false,
      uploadLoaded: 0.0,
      uploadTotal: 0.0,
      quillReady: 0
    };
  },

  watch: {
    modelValue: {
      handler(nv, ov) {
        // if (this.hasFocus) {
        //   return
        // }
        this.content = nv; // this.$nextTick(() => {
        //   if (this.quillReady) {
        //     this.quill.blur()
        //   }
        // })
      },

      immediate: true
    }
  },
  components: {},
  computed: {
    toolbarDom() {
      const toolbarDom = document.querySelector(this.toolbarId);
      return toolbarDom;
    },

    toolbarId() {
      return 'quill_toolbar' + this.$.uid;
    },

    fileId() {
      return 'quill_file' + this.$.uid;
    },

    quillId() {
      return 'quill_editor' + this.$.uid;
    },

    toolbarRef() {
      return this.$refs && this.$refs[this.toolbarId];
    },

    fileRef() {
      return this.$refs && this.$refs[this.fileId];
    },

    quillRef() {
      return this.$refs && this.$refs[this.quillId];
    },

    uploadProgress() {
      return this.uploadLoaded / this.uploadTotal || 0.0;
    }

  },

  mounted() {
    this.content = this.modelValue;
    this.contentOld = this.content;
  },

  methods: {
    getEditorOptions() {
      var editorOptions = {
        modules: {
          toolbar: '#' + this.toolbarId,
          keyboard: {
            bindings: {
              // 阻止回车键默认行为
              enter: {
                key: 13,
                handler: () => {
                  return false; // 返回 false 阻止默认行为
                }
              }
            }
          },
          // imageDrop: true,
          imageResize: true
        },
        placeholder: ''
      };
      return editorOptions;
    },

    readyEditor(e) {
      this.quill = markRaw(e);
      this.quill.getModule('toolbar').addHandler('image', this.imageHandler);
      this.quill.getModule('toolbar').addHandler('video', this.videoHandler);
      this.quill.getModule('toolbar').addHandler('video-link', this.videoLinkHandler);
      this.quill.getModule('toolbar').addHandler('file', this.fileHandler);
      this.quillReady = 1;
      this.$emit('ready', e);
    },

    focusEditor(e) {
      this.hasFocus = true;
      this.$emit('focus', e);
    },

    blurEditor(e) {
      if (!this.hasFocus) {
        return;
      }

      this.hasFocus = false;

      if (this.changeCount) {
        this.$emit('change', {
          quill: e,
          nv: this.content,
          ov: this.contentOld
        });
        this.changeCount = 0;
      }

      this.$emit('blur', e);
    },

    changeEditor(e) {
      this.changeCount++;
      this.$emit('update:modelValue', this.content);
    },

    imageHandler(e) {
      if (this.disabled) {
        return;
      }

      if (this.fileRef) {
        this.type = 'image';
        this.fileRef.accept = 'image/*';
        this.fileRef.click();
      }
    },

    videoHandler(e) {
      if (this.disabled) {
        return;
      }

      if (this.fileRef) {
        this.type = 'video';
        this.fileRef.accept = 'video/*';
        this.fileRef.click();
      }
    },

    fileHandler(e) {
      if (this.disabled) {
        return;
      }

      if (this.fileRef) {
        this.type = 'file';
        this.fileRef.accept = '';
        this.fileRef.click();
      }
    },

    changeFile(e) {
      var files = e.target.files;

      if (files.length == 0) {
        return;
      } // FormData


      var formData = new FormData();

      for (let index = 0; index < files.length; index++) {
        let file = files[index];
        formData.append('file' + index, file);
      }

      this.fileRef.value = '';
      this.$axios.post(this.url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: e => {
          this.uploadLoaded = e.loaded;
          this.uploadTotal = e.total;
        }
      }).then(res => {
        if (res.data.errno) {} else {
          var range = this.quill.getSelection(true);
          var index = range.index;

          if (index < 0) {
            index = 0;
          }

          for (const _file of res.data.data) {
            this.quill.insertEmbed(index, this.type, _file.url, 'api');
            var format = {};

            if (this.type == 'file') {
              format = {
                fileName: _file.name
              };
            } else {// format={
              //   width:200,
              //   height:150
              // }
            }

            this.quill.formatText(index, 1, format);
            index++;
            this.quill.setSelection(index);
          }

          index++;
          this.quill.insertText(index, '');
        }
      }).catch(err => {
        console.error(err);
        this.$notify({
          title: this._t('error'),
          type: 'error',
          message: this._t('maxUpload')
        });
      });
    },

    videoLinkHandler(e) {
      if (this.disabled) {
        return;
      }

      var range = this.quill.getSelection();
      var value = prompt('What is the video URL');

      if (value) {
        this.quill.insertEmbed(range.index, 'video-link', value);
        this.quill.setSelection(range.index + 1, 0);
      }
    }

  }
};