import config from '/config.js';
import { mapState, mapGetters } from 'vuex';
import Tree from '@/control/Tree.vue';
import FloatMenu from '@/control/FloatMenu.vue';
import Modal from '@/control/Modal.vue';
import MultipleSelect from '@/control/MultipleSelect.vue';
import MultipleOption from '@/control/MultipleOption.vue';
import Ticket from '@/control/Ticket.vue';
export default {
  inject: ['identify'],

  data() {
    return {
      fold: false,
      height: 74,
      nodeObj: {},
      floatMenu: {
        show: false,
        x: 0,
        y: 0,
        questionTypeParent: undefined,
        questionType: undefined,
        question: undefined
      },
      menuModal: {
        show: false,
        title: '',
        newTitle: '',
        submit: () => {}
      },
      ticket: {
        show: false
      }
    };
  },

  components: {
    Tree,
    FloatMenu,
    Modal,
    MultipleSelect,
    MultipleOption,
    Ticket
  },
  watch: {
    isPC(nv, ov) {
      this.fold = !this.isPC;
    }

  },
  computed: { ...mapState({
      isPC: 'isPC',
      questionId: 'questionId',
      nav: 'nav',
      item: 'item',
      user: 'user',
      itemFilter: 'itemFilter',
      userType: 'userType'
    }),
    ...mapGetters({
      catalog: 'getCatalog',
      getItemName: 'getItemName',
      getValidItem: 'getValidItem',
      questionRecordCount: 'getQuestionRecordCount',
      questionRecordUnreadCount: 'getQuestionRecordUnreadCount',
      questionRecordUnsolvedCount: 'getQuestionRecordUnsolvedCount'
    })
  },
  methods: {
    clickNode(node) {//this.$store.commit('changeNav', node.nav)
    },

    clickElement(node, element) {
      this.$store.commit('changeNav', node.nav);
      this.$store.commit('changeQuestionId', element.id);
      this.$store.commit('changeComponent', 'AnswerQuestion');
    },

    clickToAfterSales() {
      if (this.questionRecordCount == 0 && this.user.id && this.userType == 'user') {
        return;
      }

      if (_.isEmpty(this.user)) {
        this.identify(this.clickToAfterSales);
        return;
      }

      this.$msgbox({
        title: 'Success',
        message: 'Will open a new window,if not,please check the setting of the browser is allowed to open a new window.',
        type: 'success'
      });
      window.open('/' + this.userType + '/chat');
    },

    clickTypeSetting(e, type) {
      this.floatMenu.questionType = type;
      this.floatMenu.question = undefined;
      this.floatMenu.x = e.clientX;
      this.floatMenu.y = e.clientY;
      this.floatMenu.show = true;
    },

    clickAddType() {
      this.menuModal.title = this._t('addType');
      this.menuModal.submit = this.clickAddTypeSubmit;
      this.menuModal.newTitle = '';
      this.menuModal.show = true; // 关闭悬浮菜单

      this.floatMenu.show = false;
    },

    clickAddTypeSubmit() {
      this.$axios.post('/api/admin/KnowledgeBase/addQuestionType', {
        parent_id: this.floatMenu.questionType.parent_id,
        title: this.menuModal.newTitle
      }).then(res => {
        if (!res.data.errno) {
          const _id = res.data.data;
          const _nav = this.floatMenu.questionType.nav;
          this.$store.commit('changeNav', _nav);
          this.floatMenu.questionTypeParent.element[_id];
        }

        this.menuModal.show = false;
      }).catch(err => {
        this.menuModal.show = false;
      });
    },

    clickAddChildType() {
      this.menuModal.title = this._t('addChildType');
      this.menuModal.submit = this.clickAddChildTypeSubmit;
      this.menuModal.newTitle = '';
      this.menuModal.show = true; // 关闭悬浮菜单

      this.floatMenu.show = false;
    },

    clickAddChildTypeSubmit() {
      this.$axios.post('/api/admin/KnowledgeBase/addQuestionType', {
        parent_id: this.floatMenu.questionType.id,
        title: this.menuModal.newTitle
      }).then(res => {
        this.menuModal.show = false;
        const _nav = this.floatMenu.questionType.nav;
        this.$store.commit('changeNav', _nav);
      }).catch(err => {
        this.menuModal.show = false;
      });
    },

    clickChangeType() {
      this.menuModal.title = this._t('changeType');
      this.menuModal.submit = this.clickChangeTypeSubmit;
      this.menuModal.newTitle = '';
      this.menuModal.show = true; // 关闭悬浮菜单

      this.floatMenu.show = false;
    },

    clickChangeTypeSubmit() {
      this.$axios.post('/api/admin/KnowledgeBase/changeQuestionType', {
        id: this.floatMenu.questionType.id,
        title: this.menuModal.newTitle
      }).then(res => {
        this.menuModal.show = false;
        const _nav = this.floatMenu.questionType.nav;
        this.$store.commit('changeNav', _nav);
      }).catch(err => {
        this.menuModal.show = false;
      });
    },

    clickDeleteType() {
      this.menuModal.title = this._t('deleteType');
      this.menuModal.submit = this.clickDeleteTypeSubmit;
      this.menuModal.newTitle = '';
      this.menuModal.show = true; // 关闭悬浮菜单

      this.floatMenu.show = false;
    },

    clickDeleteTypeSubmit() {
      this.$axios.post('/api/admin/KnowledgeBase/deleteQuestionType', {
        id: this.floatMenu.questionType.id
      }).then(res => {
        this.menuModal.show = false;

        const _nav = _.cloneDeep(this.floatMenu.questionType.nav);

        _nav.splice(_nav.length - 1, 1);

        this.$store.commit('changeNav', _nav);
      }).catch(err => {
        this.menuModal.show = false;
      });
    },

    clickAddChildQuestion() {
      this.menuModal.title = this._t('addChildQuestion');
      this.menuModal.submit = this.clickAddChildQuestionSubmit;
      this.menuModal.newTitle = '';
      this.menuModal.show = true; // 关闭悬浮菜单

      this.floatMenu.show = false;
    },

    clickAddChildQuestionSubmit() {
      this.$axios.post('/api/admin/KnowledgeBase/addQuestion', {
        question_type_id: this.floatMenu.questionType.id,
        title: this.menuModal.newTitle
      }).then(res => {
        if (!res.data.errno) {
          const _nav = this.floatMenu.questionType.nav;
          this.$store.commit('changeNav', _nav);
          this.$store.commit('changeQuestionId', res.data.data);
        }

        this.menuModal.show = false;
      }).catch(err => {
        this.menuModal.show = false;
      });
    },

    clickElementSetting(e, type, question) {
      this.floatMenu.questionType = type;
      this.floatMenu.question = question;
      this.floatMenu.x = e.clientX;
      this.floatMenu.y = e.clientY;
      this.floatMenu.show = true;
    },

    clickDeleteQuestion() {
      this.menuModal.title = this._t('deleteQuestion');
      this.menuModal.submit = this.clickDeleteQuestionSubmit;
      this.menuModal.newTitle = '';
      this.menuModal.show = true; // 关闭悬浮菜单

      this.floatMenu.show = false;
    },

    clickDeleteQuestionSubmit() {
      this.$axios.post('/api/admin/KnowledgeBase/deleteQuestion', {
        id: this.floatMenu.question.id
      }).then(res => {
        this.menuModal.show = false;
        const _nav = this.floatMenu.question.nav;
        this.$store.commit('changeNav', _nav);
      }).catch(err => {
        this.menuModal.show = false;
      });
    },

    changeItemFilter({
      nv,
      ov
    }) {
      this.$store.commit('changeItemFilter', nv);
    },

    clickUnsolved(e, type, question) {
      if (_.isEmpty(this.user)) {
        this.identify(this.clickUnsolved, e, type, question);
        return;
      }

      this.floatMenu.questionType = type;
      this.floatMenu.question = undefined;
      this.menuModal.title = this._t('addQuestion');
      this.menuModal.submit = this.clickUnsolvedSubmit;
      this.menuModal.newTitle = '';
      this.menuModal.show = true;
    },

    clickUnsolvedSubmit() {
      this.$axios.post('/api/user/KnowledgeBase/addQuestionRecord', {
        question_type_id: this.floatMenu.questionType.id,
        title: this.menuModal.newTitle
      }).then(res => {
        if (!res.data.errno) {
          this.$store.dispatch('openChat');
          this.menuModal.show = false;
        }
      }).catch(err => {
        console.log(err);
      });
    },

    getItemsName(item) {
      var nameArr = [];

      if (_.isEmpty(item)) {
        return '';
      }

      for (let _item of item) {
        nameArr.push(this.getItemName(_item));
      }

      return nameArr.join();
    },

    clickCopyQuestionLink() {
      // var url=window.location.host
      var url = config.helpUrl(this.isPC) || window.location.host;
      var nav = JSON.stringify(this.floatMenu.question.nav);
      var id = 'wwt' + this.floatMenu.question.id;
      var params = 'nav=' + Buffer.from(nav).toString('base64') + '&id=' + Buffer.from(id).toString('base64');
      url = url + '?' + params;
      this.$copyText(url).then(e => {
        alert('Copy successful:' + url);
      }).catch(err => {
        alert('Copy error:' + err);
      });
    },

    clickTicket(e, questionType) {
      if (_.isEmpty(this.user)) {
        this.identify(this.clickTicket, e, questionType);
        return;
      }

      this.ticket.questionType = questionType;
      this.ticket.show = true;
    },

    clickFoldPanel() {
      this.fold = !this.fold;
    }

  }
};