import "core-js/modules/es.array.includes.js";
import config from '/config.js';
import { mapState } from 'vuex';
import Modal from '@/control/Modal.vue';
import Notice from '@/control/Notice.vue';
import { ElNotification } from 'element-plus';
export default {
  components: {
    Modal,
    Notice
  },

  data() {
    return {
      url: `${window.location.protocol == 'https:' ? 'wss' : 'ws'}://${window.location.host}/ws_js`,
      userChatUrl: `${window.location.protocol == 'https:' ? 'wss' : 'ws'}://${window.location.host}/java/faqwebsocket.ws`,
      socket: undefined,
      loginModalShow: false,
      msgModal: {
        show: false,
        title: '',
        msg: ''
      },
      account: '',
      password: '',
      store: 'store',
      identifyTime: 0,
      identifyDelay: 1500,
      identifying: false,
      loginHint: '',
      socketing: false,
      waitShow: false,
      waitObject: {},
      loginNext: {
        func: () => {},
        param: []
      },
      uploadFailedNext: {
        func: () => {},
        param: []
      },
      workTimeModal: {
        show: false,
        title: 'Service Time',
        timer: {},
        cnLocalTime: '',
        hourBeg: '9',
        hourEnd: '18',
        weekBeg: '1',
        weekEnd: '5'
      },
      homeNotice: {
        html: '',
        show: false
      },
      cookieString: '',
      webSocket: '',
      messageData: {},
      userId: 0,
      webSocketTimer: undefined,
      webSocketTimeOutTimer: undefined,
      recordId: undefined,
      source: '',
      sourceType: '',
      userAdminWSTimeOut: 0
    };
  },

  computed: { ...mapState({
      isPC: 'isPC',
      wsid: 'wsid',
      timestamp: 'timestamp',
      user: 'user',
      userType: 'userType',
      base: 'base',
      questionTemp: 'questionTemp',
      href: 'href',
      questionRecordId: 'questionRecordId'
    }),
    ...mapState('moblie', {
      navText: 'navText'
    }),

    htmlShow() {
      var show = true;

      if (this.userType == 'admin' && this.loginModalShow && _.isEmpty(this.user)) {
        show = false;
      }

      return show;
    },

    isInWorkingTime() {
      var hour = this.$moment().utcOffset(8).hour();
      var week = this.$moment().utcOffset(8).weekday();
      var isInHour = hour >= this.workTimeModal.hourBeg && hour <= this.workTimeModal.hourEnd;
      var isInWeek = week >= this.workTimeModal.weekBeg && week <= this.workTimeModal.weekEnd;

      if (isInHour && isInWeek) {
        return true;
      }

      return false;
    }

  },
  watch: {
    questionRecordId: {
      handler(nv, ov) {
        if (nv === 0) {
          return;
        }

        this.recordId = nv;
      },

      immediate: true
    }
  },

  beforeMount() {
    // 服务时间
    this.workTimeModal.title = this._t('serviceTime'); // 监听unload,保存当前store.state

    window.addEventListener('unload', this.saveStoreState); // 清除user和userType

    this.$store.commit('changeUser', {});
    this.$store.commit('changeUserType', '');
    this.$store.commit('changeAxios', this.$axios);
    this.$store.commit('moblie/changeAxios', this.$axios);
    this.$store.commit('moblie/changeI18n', this.$t); // request拦截器，发送接收到的后台服务器最新时间戳

    this.$axios.interceptors.request.use(config => {
      config.data = config.data || {};

      if (!config.isMoblie && !config.url.includes('/java')) {
        if (Object.prototype.toString.call(config.data) === '[object FormData]') {
          config.data.append('timestamp', this.timestamp);
          config.data.append('wsid', this.wsid);
        } else {
          config.data.timestamp = this.timestamp;
          config.data.wsid = this.wsid;
        }
      }

      this.waitObject.state = true;
      this.waitObject.url = config.url;
      return config;
    }, err => {
      this.waitShow = false;
      this.waitObject.state = false;
      return Promise.reject(err);
    }); // response拦截器，提示设置失败

    this.$axios.interceptors.response.use(res => {
      this.waitShow = false;
      this.waitObject.state = false;

      if (res.data.errno == 1001) {
        // 由于本地数据不是最新，导致设置失败
        this.msgModal.title = this._t('alterFailed');
        this.msgModal.content = this._t('alterFailedHint');
        this.msgModal.show = true;
      } else if (res.data.errno == 1003) {
        // 无效session,重新验证
        this.identify();
      }

      return res;
    }, err => {
      this.waitShow = false;
      this.waitObject.state = false;
      return Promise.reject(err);
    });
  },

  mounted() {
    setTimeout(async () => {
      const res = await this.$axios.get('/api/service/getInfo');

      if (!res || res.status != 200) {
        return;
      }

      window.STORE_SERVER = res.data.data.STORE_SERVER;
      window.FAQ_WEB = res.data.data.FAQ_WEB;
    }); // 监听宽度

    const it = this;
    this.resize();
    window.addEventListener('resize', this.resize); // url参数

    this.$router.isReady().then(() => {
      var params = it.$route.query;

      if (_.isEmpty(params) || !_.isEmpty(it.questionTemp) && it.href == window.location.href) {
        return;
      }

      var nav = Buffer.from(params.nav, 'base64').toString();
      var id = Buffer.from(params.id, 'base64').toString();
      id = Number(id.slice(3));
      this.$store.commit('changeQuestionTemp', {});
      nav && it.$store.commit('changeNav', JSON.parse(nav));
      id && it.$store.commit('changeQuestionId', id);
      it.$store.commit('changeBase', 'KnowledgeBase');
      it.$store.commit('changeComponent', 'AnswerQuestion');
    }).catch(err => {
      console.log(err);
    });
    this.$watch('userType', () => {
      // 初始化websocket
      this.initWebSocketUserChat();
      this.initWebSocket(); // 提示工作时间

      if (!it.isInWorkingTime && it.userType == 'user' && it.userType == 'admin' && it.base == 'ChatBase') {
        it.workTimeModal.timer = setInterval(() => {
          it.workTimeModal.cnLocalTime = it.$moment().utcOffset(8).format('dddd,HH:mm:ss');
        }, 1000);
        it.workTimeModal.show = true;
      } // 获取notice


      if (this.userType == 'user' && this.base == 'ChatBase') {
        this.getNotice();
      }
    });
    this.$watch('loginModalShow', nv => {
      if (!nv) {
        this.loginNextFunc();
      }
    });
  },

  beforeUnmount() {
    clearInterval(this.webSocketTimer);
  },

  unmounted() {
    this.socket && this.socket.close();
    this.webSocket && this.webSocket.close();
    window.removeEventListener('resize', this.resize);
    clearInterval(this.workTimeModal.timer);
  },

  provide() {
    return {
      send: this.send,
      identify: this.identify,
      uploadSuccess: this.uploadSuccess,
      uploadFailed: this.uploadFailed,
      waitObject: this.waitObject
    };
  },

  methods: {
    saveStoreState() {
      var localState = {
        href: window.location.href,
        nav: this.$store.state.nav,
        base: this.$store.state.base,
        component: this.$store.state.component,
        questionTemp: this.$store.state.questionTemp,
        stepTemp: this.$store.state.stepTemp,
        questionId: this.$store.state.questionId
      };
      const key = window.location.pathname + '/localState';
      localStorage.setItem(key, JSON.stringify(localState));
    },

    loginNextFunc() {
      if (typeof this.loginNext.func !== 'function') {
        return;
      }

      if (_.isEmpty(this.user)) {
        return;
      }

      this.loginNext.func(...this.loginNext.param);
    },

    postMsg(event, msg) {
      if (event === 'login' && msg === true) {
        if (this.goToExternalLogin()) {
          return false;
        }
      }

      var data = {};
      data[event] = msg;

      if (window.parent != window) {
        window.parent.postMessage(JSON.stringify(data), '*');
      }

      return true;
    },

    goToExternalLogin() {
      if (this.isPC) {
        return false;
      }

      if (_.isEmpty(this.navText) || _.isEmpty(this.navText.login)) {
        return false;
      }

      const href = this.navText.login + '?bkurl=' + window.location.href;
      window.location.href = href;
      return true;
    },

    backIdentify() {
      const it = this;
      var url = '/api/' + this.userType + '/Base/identify';
      it.$axios.post(url, {}).then(res => {
        if (res.data.errno) {} else {
          it.$store.commit('changeUser', res.data.data);
          it.login();
          it.loginModalShow = false;
        }
      }).catch(err => {
        console.log(err);
      });
    },

    identify(func, ...param) {
      const it = this; // 缓存关闭登录悬浮窗后需要调用的函数以及对应参数

      it.loginNext.func = func;
      it.loginNext.param = param; // 打开悬浮窗

      it.loginModalShow = true;
      it.loginHint = '';
      it.identifying = true;
      it.identifyTime = Date.now();
      var url = '/api/' + this.userType + '/Base/identify';
      it.$axios.post(url, {}).then(res => {
        if (res.data.errno) {
          // 调用外部登录
          const ret = it.postMsg('login', true);

          if (window.parent != window) {
            it.loginModalShow = false;
            return;
          }

          if (!this.isPC && !ret) {
            // it.loginModalShow = false
            return;
          }
        } else {
          it.$store.commit('changeUser', res.data.data);
          it.login();
        }

        const _offset = Date.now() - it.identifyTime;

        if (_offset < it.identifyDelay) {
          setTimeout(() => {
            if (res.data.errno == 0) {
              it.loginModalShow = false;
            }

            it.identifying = false;
          }, it.identifyDelay - _offset);
        } else {
          if (res.data.errno == 0) {
            it.loginModalShow = false;
          }

          it.identifying = false;
        }
      }).catch(err => {
        console.error(this._t('reconnect') + err);
      });
    },

    clickLogin() {
      const it = this;
      var url = '/api/' + this.userType + '/Base/login';
      var postData = {
        account: it.account,
        password: it.password
      };

      if (this.userType == 'user') {
        postData.store = this.store;
      }

      it.loginHint = '';
      it.$axios.post(url, postData).then(res => {
        if (res.data.errno) {
          it.loginHint = res.data.errmsg;
          console.warn(res.data.errmsg);
        } else {
          it.postMsg('login', false);
          it.backIdentify();
        }
      }).catch(err => {
        console.error(err);
      });
    },

    initWebSocket() {
      const it = this;

      if (!(this.userType == 'user' && this.base == 'KnowledgeBase')) {
        it.loginHint = '';
        it.loginModalShow = true;
      }

      if (typeof WebSocket === 'undefined') {
        console.warn('Your browser not support WebSocket');
      } else {
        it.socket = undefined;
        it.socket = new WebSocket(it.url);
        it.socketing = true;
        it.socket.onopen = it.open;
        it.socket.onerror = it.error;
        it.socket.onmessage = it.get;
        it.socket.onclose = it.close;
        setTimeout(() => {
          if (it.socketing) {
            if (it.socket.readyState != 1) {
              it.socket.close();
            }

            setTimeout(() => {
              it.initWebSocket();
            }, 1000);
          }
        }, 5000);
      }
    },

    open() {
      if (this.socketing) {
        this.socketing = false;
      }

      console.log('ws open');
    },

    error(event) {
      console.log('ws error', event);
    },

    close() {
      console.log('ws close');

      if (!this.socketing) {
        this.initWebSocket();
      }
    },

    get(msg) {
      var data = JSON.parse(msg.data); // 按照指定命名来更新store中的数据库表，方便后面扩展。('update'+首字母大写的数据库表名)

      this.$store.commit('changeTimestamp', data.data.timestamp);

      if (data.event == 'wsid') {
        this.$store.commit('changeWsid', data.data.id); // 管理员识别是否登录

        if (this.userType == 'user' && this.base == 'KnowledgeBase') {
          this.backIdentify();
        } else {
          this.identify();
        }
      } else if (data.event == 'updateTable') {
        this.$store.commit(data.event, data.data);
      } else if (data.event == 'updateTablePart') {
        this.$store.commit(data.event, data.data);
      }
    },

    send(event, msg) {
      var pack = {
        event: event,
        data: {
          user: this.user,
          userType: this.userType
        }
      };

      if (msg) {
        pack.data.msg = msg;
      }

      this.socket && this.socket.readyState == 1 && this.socket.send(JSON.stringify(pack));
    },

    login() {
      this.send('login');
    },

    initWebSocketUserChat() {
      if (typeof WebSocket === 'undefined') {
        console.warn('Your browser not support WebSocket');
      } else {
        this.webSocket = new WebSocket(this.userType == 'admin' ? this.userChatUrl + '?type=0' : this.userChatUrl + '?type=1');
        this.webSocket.onerror = this.userChatWebSocketError; // 监听WebSocket连接成功建立

        this.webSocket.onopen = this.userChatWebSocketOpen; // 监听WebSocket接收到消息

        this.webSocket.onmessage = this.userChatWebSocketMessage; // 监听WebSocket连接关闭

        this.webSocket.onclose = this.userChatWebSocketClose;
      }
    },

    userChatWebSocketError() {
      console.log('WebSocket连接发生错误');
    },

    // 监听WebSocket连接成功建立
    userChatWebSocketOpen() {
      // 心跳消息
      // if (this.messageData.type === 'init') {
      //   if (this.messageData.source == '1') {
      //       this.sendQuestion()
      //   } else if(this.messageData.source == '0') {
      //       this.sendAdminQuestion()
      //   }
      // }
      this.userAdminWSTimeOut = Date.now();
      this.webSocketTimer = setInterval(() => {
        this.sendMessage();
      }, 10000);
      this.webSocketTimeOutTimer = setInterval(() => {
        if (this.userAdminWSTimeOut + 30000 < Date.now()) {
          this.webSocket.close();
        }
      }, 2000);
      console.log("WebSocket连接成功");
    },

    // 监听WebSocket接收到消息
    userChatWebSocketMessage(event) {
      if (event.data.includes('服务器错误')) {
        return;
      }

      this.messageData = JSON.parse(event.data); // console.log('收到的消息',this.messageData);

      if (this.messageData.type === 'init') {
        this.userId = this.messageData.userId;

        if (this.messageData.source == '1') {
          setTimeout(() => {
            this.sendQuestion();
          }, 2000);
        } else if (this.messageData.source == '0') {
          setTimeout(() => {
            this.sendAdminQuestion();
          }, 2000);
        }
      } else if (this.messageData.type === 'event-userApply') {
        this.$store.commit('changeIsScorePanelShow', true);
      } else if (this.messageData.type === 'pong') {
        this.userAdminWSTimeOut = Date.now();
      }

      this.$eventBus.emit(this.messageData.type, this.messageData);
    },

    // 监听WebSocket连接关闭
    userChatWebSocketClose() {
      clearInterval(this.webSocketTimer);
      clearInterval(this.webSocketTimeOutTimer);
      console.log("WebSocket连接关闭");
      setTimeout(() => {
        this.initWebSocketUserChat();
      }, 2000);
    },

    sendMessage() {
      this.source = '';

      if (this.userType == 'user') {
        this.source = 'user';
      } else {
        this.source = 'admin';
      }

      const msg = {
        type: 'ping',
        userId: this.userId,
        source: this.source
      };

      if (this.webSocket && this.webSocket.readyState === WebSocket.OPEN) {
        this.webSocket.send(JSON.stringify(msg)); // console.log('发送成功', msg);
      }
    },

    // 用户
    async sendQuestion() {
      if (this.messageData.type === 'init') {
        const res = await this.$axios.post('/java/front/sendQuestion', {
          userId: this.userId,
          type: 'userLogin',
          questionRecordId: this.recordId
        });
      }
    },

    // 管理人员
    async sendAdminQuestion() {
      if (this.messageData.type === 'init') {
        const res = await this.$axios.post('/java/front/sendAdminQuestion', {
          userId: this.userId,
          type: 'adminLogin',
          questionRecordId: this.recordId
        });
      }
    },

    uploadFailedNextFunc() {
      if (typeof this.uploadFailedNext.func !== 'function') {
        return;
      }

      this.uploadFailedNext.func(...this.uploadFailedNext.param);
    },

    uploadFailed(func, ...param) {
      ElNotification({
        title: this._t('warning'),
        message: this._t('modifyFaild'),
        type: 'warning'
      });
      this.uploadFailedNext.func = func;
      this.uploadFailedNext.param = param;
      this.waitShow = true;
      setTimeout(() => {
        this.waitShow = false;
        this.uploadFailedNextFunc();
      }, 3000);
    },

    uploadSuccess() {
      ElNotification({
        title: this._t('successful'),
        message: this._t('modifySuccessful'),
        type: 'success'
      });
    },

    resize() {
      var isMobile = !!(navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i) || document.body.clientWidth <= 1080);

      if (this.isPC == isMobile) {
        this.$store.commit('changeIsPC', !this.isPC);
      }
    },

    async getNotice() {
      const res = await this.$axios.post('/api/notice/getNotice', {
        id: 1
      });

      if (res.status != 200 || res.data.errno) {
        return;
      }

      const {
        html,
        enable
      } = res.data.data;

      if (!enable) {
        return;
      }

      this.homeNotice.show = true;
      this.homeNotice.html = html;
    }

  }
};