import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, normalizeStyle as _normalizeStyle, withCtx as _withCtx, withModifiers as _withModifiers, withKeys as _withKeys, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-5b04d0e1"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "sale-container"
};
const _hoisted_2 = {
  class: "sale-left",
  style: {
    "padding-bottom": "unset"
  }
};
const _hoisted_3 = ["title"];
const _hoisted_4 = {
  class: "problem-group",
  style: {
    "height": "666px"
  }
};
const _hoisted_5 = ["onClick"];
const _hoisted_6 = {
  class: "time"
};
const _hoisted_7 = {
  class: "dec"
};
const _hoisted_8 = {
  key: 0,
  class: "unread",
  style: {
    "top": "5px"
  }
};
const _hoisted_9 = {
  key: 1,
  class: "solveddiv"
};
const _hoisted_10 = {
  class: "solved"
};
const _hoisted_11 = {
  key: 2,
  class: "unresolve"
};
const _hoisted_12 = {
  key: 3,
  style: {
    "position": "absolute",
    "right": "26px",
    "bottom": "0px",
    "text-align": "right",
    "width": "300px"
  }
};
const _hoisted_13 = {
  class: "score",
  style: {
    "text-align": "right"
  }
};
const _hoisted_14 = {
  class: "sale-right",
  style: {
    "position": "relative",
    "left": "0px"
  }
};
const _hoisted_15 = {
  class: "chat",
  ref: "chat"
};
const _hoisted_16 = ["innerHTML"];
const _hoisted_17 = {
  class: "tool"
};
const _hoisted_18 = {
  class: "tool-uploaddiv"
};
const _hoisted_19 = {
  style: {
    "display": "flex",
    "flex-direction": "column"
  }
};
const _hoisted_20 = {
  style: {
    "display": "flex",
    "flex-direction": "row",
    "align-items": "center"
  }
};
const _hoisted_21 = {
  style: {
    "font-size": "13px"
  }
};
const _hoisted_22 = {
  class: "score-btn"
};
const _hoisted_23 = {
  class: "input-sr"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_rate = _resolveComponent("el-rate");

  const _component_el_image_viewer = _resolveComponent("el-image-viewer");

  const _component_ScorePanel = _resolveComponent("ScorePanel");

  const _component_Upload = _resolveComponent("Upload");

  const _component_el_icon_CircleCheckFilled = _resolveComponent("el-icon-CircleCheckFilled");

  const _component_el_icon = _resolveComponent("el-icon");

  const _component_el_icon_CircleCloseFilled = _resolveComponent("el-icon-CircleCloseFilled");

  const _component_el_input = _resolveComponent("el-input");

  const _component_el_button = _resolveComponent("el-button");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
    class: "ticket",
    title: _ctx._t('unread') + '/' + _ctx._t('unresolved')
  }, _toDisplayString(_ctx._t("afterSalesQuestion")) + "(" + _toDisplayString(_ctx.questionRecordUnreadCount) + "/" + _toDisplayString(_ctx.questionRecordUnsolvedCount) + ") ", 9, _hoisted_3), _createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.questionRecordSort, (_record, _recordIndex) => {
    return _openBlock(), _createElementBlock("div", {
      key: _recordIndex,
      class: _normalizeClass(["problem-div", [_record.id == _ctx.questionRecordId ? 'activity' : '']]),
      style: {
        "word-break": "break-all"
      },
      onClick: $event => $options.clickQuestionRecord(_record)
    }, [_createElementVNode("div", _hoisted_6, _toDisplayString($options.getLocalTime(_record.create_time)), 1), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", null, _toDisplayString($options.getNav(_record.id).join(">") + ">" + $options.getTitle(_record.id)), 1)]), _record.user_unread && _record.is_solved == 0 ? (_openBlock(), _createElementBlock("div", _hoisted_8)) : _createCommentVNode("", true), _record.is_solved ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, "[" + _toDisplayString(_ctx._t("solved")) + "]", 1)])) : (_openBlock(), _createElementBlock("div", _hoisted_11, "[" + _toDisplayString(_ctx._t("unsolved")) + "]", 1)), _record.score && _record.score.length ? (_openBlock(), _createElementBlock("div", _hoisted_12, [_createElementVNode("div", _hoisted_13, [_createTextVNode(_toDisplayString(_ctx._t('rate')), 1), _createVNode(_component_el_rate, {
      modelValue: _record.score[_record.score.length - 1].level,
      "onUpdate:modelValue": $event => _record.score[_record.score.length - 1].level = $event,
      disabled: "",
      "text-color": "#ff9900"
    }, null, 8, ["modelValue", "onUpdate:modelValue"])])])) : _createCommentVNode("", true)], 10, _hoisted_5);
  }), 128))])]), _createElementVNode("div", _hoisted_14, [$data.imgViewerShow ? (_openBlock(), _createBlock(_component_el_image_viewer, {
    key: 0,
    onClose: _cache[0] || (_cache[0] = $event => $data.imgViewerShow = false),
    infinite: false,
    "url-list": $data.imgArray,
    "initial-index": $data.imgIndex
  }, null, 8, ["url-list", "initial-index"])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_15, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.chatReocrd, (_chat, _chatIndex) => {
    return _openBlock(), _createElementBlock("div", {
      key: _chatIndex,
      class: _normalizeClass(["chatdiv", [_chat.user.id ? 'chatdiv-right' : '']])
    }, [_chat.type != 'score' ? (_openBlock(), _createElementBlock(_Fragment, {
      key: 0
    }, [_createElementVNode("div", {
      class: "name",
      style: _normalizeStyle([{
        "flex-basis": "100%"
      }, {
        'text-align': _chat.user.id ? 'right' : ''
      }])
    }, [_createElementVNode("span", {
      style: _normalizeStyle({
        'background-color': _ctx.getPersonBackgroundColor(_chat.person_id)
      })
    }, _toDisplayString(_chat.user.id ? _chat.user.name : _ctx.getPersonName(_chat.person_id)) + " [" + _toDisplayString($options.getLocalTime(_chat.time)) + "]", 5)], 4), _createElementVNode("div", {
      class: "txt",
      innerHTML: _chat.new_content
    }, null, 8, _hoisted_16)], 64)) : (_openBlock(), _createElementBlock(_Fragment, {
      key: 1
    }, [_createElementVNode("div", {
      class: "name",
      style: _normalizeStyle([{
        "flex-basis": "100%"
      }, {
        'text-align': _chat.user.id ? 'right' : ''
      }])
    }, [_createElementVNode("span", {
      style: _normalizeStyle({
        'background-color': _ctx.getPersonBackgroundColor(_chat.person_id)
      })
    }, _toDisplayString(_chat.user.id ? _chat.user.name : _ctx.getPersonName(_chat.person_id)) + " [" + _toDisplayString($options.getLocalTime(_chat.time)) + "]", 5)], 4), _createVNode(_component_ScorePanel, {
      scoreSelected: _chat.score
    }, null, 8, ["scoreSelected"])], 64))], 2);
  }), 128))], 512), _createElementVNode("div", _hoisted_17, [_createElementVNode("div", _hoisted_18, [_createVNode(_component_Upload, {
    url: $data.uploadUrl,
    onOnSuccess: $options.onSuccessUpload,
    disabled: $options.userDisabled,
    fileType: "image"
  }, null, 8, ["url", "onOnSuccess", "disabled"]), _createVNode(_component_Upload, {
    url: $data.uploadUrl,
    onOnSuccess: $options.onSuccessUpload,
    disabled: $options.userDisabled,
    fileType: "video"
  }, null, 8, ["url", "onOnSuccess", "disabled"]), _createVNode(_component_Upload, {
    url: $data.uploadUrl,
    onOnSuccess: $options.onSuccessUpload,
    disabled: $options.userDisabled,
    fileType: "file"
  }, null, 8, ["url", "onOnSuccess", "disabled"])]), _createElementVNode("div", _hoisted_19, [_createElementVNode("div", _hoisted_20, [_createElementVNode("p", null, _toDisplayString(_ctx._t('CustomerServiceOnlineStatus')) + "：", 1), $setup.isOnLine ? (_openBlock(), _createBlock(_component_el_icon, {
    key: 0,
    size: 18,
    color: "green"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_icon_CircleCheckFilled)]),
    _: 1
  })) : (_openBlock(), _createBlock(_component_el_icon, {
    key: 1,
    size: 18,
    color: "red"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_icon_CircleCloseFilled)]),
    _: 1
  }))]), _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx._t("Onlinetime")), 1)]), _createElementVNode("div", _hoisted_22, [_createElementVNode("div", {
    onClick: _cache[1] || (_cache[1] = $event => $options.clickSolved($options.questionRecordSelected))
  }, " [ " + _toDisplayString($options.questionRecordSelected.is_solved ? _ctx._t("unsolved") : _ctx._t("solved")) + " ] ", 1)])]), _createElementVNode("div", _hoisted_23, [_createVNode(_component_el_input, {
    type: "textarea",
    placeholder: $options.userDisabled ? '' : _ctx._t('sendPlaceholder'),
    modelValue: $data.sendContent,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $data.sendContent = $event),
    disabled: $options.userDisabled,
    onKeydown: _cache[3] || (_cache[3] = _withKeys(_withModifiers($event => $options.clickSendContent($event), ["exact"]), ["enter"])),
    onKeyup: _cache[4] || (_cache[4] = _withKeys($event => $data.sendContent = '', ["enter"])),
    "show-word-limit": true,
    maxlength: "300"
  }, null, 8, ["placeholder", "modelValue", "disabled"]), _createVNode(_component_el_button, {
    class: "input-btn",
    size: "small",
    round: "",
    onClick: _cache[5] || (_cache[5] = $event => $options.clickSendContent($event)),
    disabled: $options.userDisabled
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx._t("send")), 1)]),
    _: 1
  }, 8, ["disabled"])]), _withDirectives(_createElementVNode("div", null, [_createVNode(_component_ScorePanel, {
    show: $data.scorePanel.show,
    "onUpdate:show": _cache[6] || (_cache[6] = $event => $data.scorePanel.show = $event),
    style: {
      "z-index": "2"
    },
    questionRecordSelected: $options.questionRecordSelected
  }, null, 8, ["show", "questionRecordSelected"])], 512), [[_vShow, $data.scorePanel.show]])])]);
}